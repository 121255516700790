import React, { useEffect } from 'react';
import CarouselGenerator from './CarouselGenerator';

function App() {
  useEffect(() => {
    document.title = "HiPost - Carousel Generator"; // This is the new title
  }, []);

  return (
    <div className="App">
      <h1 className="text-3xl font-bold text-center my-4">Carousel Generator</h1>
      <CarouselGenerator />
    </div>
  );
}

export default App;
