// SlideThumbnail.jsx
import React from 'react';

export default function SlideThumbnail({ content }) {
  return (
    <div
      className="
        w-[270px] h-[338px]  /* quarter-size of 1080x1350 */
        bg-black text-white flex items-center justify-start
        font-roboto
      "
    >
      <p className="text-[24px] font-black leading-[1.2] text-left text-white px-4 whitespace-pre-wrap">
        {content}
      </p>
    </div>
  );
}
