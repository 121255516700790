import React, { useState } from 'react';
import SlideThumbnail from './SlideThumbnail';

const CarouselGenerator = () => {
    // State to hold the user input text and the slides returned by the backend
    const [inputText, setInputText] = useState('');
    const [slides, setSlides] = useState([]);

    // Event handler for the form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        try {
            // Make a POST request to the FastAPI /generate endpoint
            const response = await fetch(`${process.env.REACT_APP_API_URL}/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text: inputText }), // Send the input text as JSON
            });

            // Parse the JSON reponse
            const data = await response.json();
            //console.log('API response:', data); // Debug: Check structure of response
            setSlides(data.slides || []);

        } catch (error) {
            console.error('Error generating slides:',)
        }
    };

    return (
        <div className="p-8">
            {/* Input Form */}
            <form onSubmit={handleSubmit} className="mb-4">
                <textarea
                    className="w-full border p-2 rounded"
                    rows="4"
                    placeholder="Enter your text here"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)} // Update state on input change
                />
                <button
                    type="submit"
                    className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Generate Slides
                </button>
            </form>

            {/* Thumbnails in a Grid */}
            <div className="grid grid-cols-1 md:grid-cols-5 gap-2">
                {slides.map((slideContent, index) => (
                    <SlideThumbnail key={index} content={slideContent} />
                ))}
            </div>
            </div>
    );
};

export default CarouselGenerator;